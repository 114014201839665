
import React, { useEffect, useState } from "react";

// Installed
import { SearchOutlined, SearchOffOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment, TextField,Button } from "@mui/material";
import axios from "axios";

// Components
import Result from "../components/Result";

const defaultImgArray = [
  "garbage",
  "garbage-truck",
  "garbage",
  "garbage-truck",
  "garbage",
  "garbage-truck",
  "industry"
];

/* eslint-disable react-hooks/exhaustive-deps */
export default function Home(props) {
  const [keywords, setKeywords] = useState("");
  const [imgArray, setImgArray] = useState([]);
  const [description, setDescription] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [advanced, setAdvanced] = useState(false);
  const [address, setAddress] = useState();

  useEffect(() => {
    const storedAddress = localStorage.getItem("address");
    if (!!storedAddress)
      setAddress(storedAddress);
    if (description === null || schedule.length === 0) {
      (async () => {
        await axios.get("page/data/" + props.isAuthorized).then((res) => {
          const {warning, description, schedule } = res.data;
          if (warning?.length > 0)
            console.warn(warning)
          setDescription(description)
          setSchedule(schedule);
        });
      })();
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (props.width > 800)
        setImgArray(defaultImgArray);
      else if (props.width <= 800 && props.width > 485)
        setImgArray(defaultImgArray.slice(2));
      else setImgArray(defaultImgArray.slice(4));
    }, 100);
  }, [props.width]);

  const onTextChange = (e) => {
    setKeywords(e.target?.value);
  };

  const handleCheckboxChange = () => {
    setAdvanced(!advanced);
    setKeywords("");
  }

  const searchWithStoredAddress = async () => {
    setKeywords(address);
    updateStatistics(address);
  }

  const updateAddress = (value = null) => {
    if(!!value){
      setAddress(value);
      updateStatistics(value);
      localStorage.setItem("address", value)
    }else{
      setAddress();
      localStorage.removeItem("address");
    }
  }

  const updateStatistics = async(address) => {
    await axios.get("page/updateStatistics/" + address);
  }

  return (
    <>
      <TextField
        name="keywords"
        className={"search-input" + (keywords?.length > 0 ? " search-input-focused" : "")}
        onChange={onTextChange}
        value={keywords}
        autoComplete="off"
        disabled={schedule?.length === 0}
        placeholder="Ex: Ågårdsvägen 31, Alvesta eller din Fastighetsbeteckning"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {keywords?.length > 0 && (
                <IconButton
                  edge="start"
                  style={{ marginRight: 5, color: "#cc0000" }}
                  onClick={() => setKeywords("")}
                >
                  <SearchOffOutlined fontSize="large" color="warning" />
                </IconButton>
              )}
              <IconButton
                edge="end"
                style={{ marginRight: 0 }}
                disabled={keywords?.length === 0}
              >
                <SearchOutlined fontSize="large" />
              </IconButton>
            </InputAdornment>
          ),
        }} />

      <FormGroup className="advanced-search" style={props?.isAuthorized ? {justifyContent: "space-between"} : null}>
        {props.isAuthorized && <FormControlLabel control={<Checkbox className="advanced-search-checkbox" 
            onChange={handleCheckboxChange} />} label="Sök med datum tecken" />}
        <Button variant="contained" color="primary" className="btn-address" title="Sök med din sparad adress"
          onClick={searchWithStoredAddress} disabled={!address}>Min adress</Button>
      </FormGroup>

      <div className="content-container home">
      
      {/* <img loading="lazy" decoding="async" className="img" src={require("../assets/background-l.jpg")} alt="" /> */}
      
        {/* Result by search keywords */}
        {keywords.length > 0 && <Result keywords={keywords} schedule={schedule} advanced={advanced} address={address} updateAddress={updateAddress} />}

        {/* Description */}
        {description && (
          <div className={`content-wrapper${keywords.length > 0 ? " hidden" : ""}`} dangerouslySetInnerHTML={{ __html: description }}></div>
        )}
        

        {/* Images wrapper */}
        <div className="images-wrapper d-flex">
          {imgArray.length > 0 &&
            imgArray.map((name, index) => (
              <img loading="lazy" decoding="async" key={index} src={require(`../assets/images/${name}.png`)} className={name} alt={name} />
            ))}
        </div>
      </div>
    </>
  );
};
