import { BarChartOutlined, ExpandLess, ExpandMore, FactCheck } from "@mui/icons-material";
import { CircularProgress, Collapse, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { TokenConfig } from "../functions/TokenConfig";

function Statistics() {
    const [list, setList] = useState();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(true);
    const [openIndex, setOpenIndex] = useState(0);

    const refIndex = useRef([]);

    useEffect(() => {
        async function getData() {
            setLoading(true);
            await axios.get("page/visit/statistics", TokenConfig()).then(res => {
                setList(res.data?.visits);
                setData(res.data?.data);
                setLoading(false);
            }, error => {
                setLoading(false);
                console.log("Get statistics, error => " + error);
            })
        }

        getData();
    }, [])

    const handleClick = (index) => {
        setOpen(!open);
        if (index === openIndex)
            setOpenIndex(null);
        else {
            if (openIndex !== null) {
                setTimeout(() => {
                    setOpen(true);
                }, 500)
            }
            setOpenIndex(index)
        }
    }

    return <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        className="content-container statistics-wrapper forms"
        subheader={
            <ListSubheader component="div" id="nested-list-subheader" color="default">
                <h1>Besöksstatistik</h1>
                {!!data &&
                    <p className="statistics-data" dangerouslySetInnerHTML={{ __html: data }}></p>}
            </ListSubheader>
        }

    >
        {(!loading && !!list) && list?.map((item, index) => {
            const isOpen = open && openIndex === index;
            const secondaryStyles = {
                color: 'primary',
                fontWeight: 'medium',
                variant: 'body2',
            }
            return <div className="statistics-view" key={index} ref={ind => refIndex.current[`index-${index}`] = ind}>
                <ListItemButton onClick={() => handleClick(index)}>
                    <ListItemIcon>
                        <FactCheck color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={item?.date} primaryTypographyProps={{
                        color: 'primary',
                        fontWeight: 'medium',
                        variant: 'body1',
                    }} />
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <BarChartOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Besökare" secondary={item?.visitors?.length} secondaryTypographyProps={secondaryStyles} />
                            <ListItemText primary="Besök" secondary={item?.count} secondaryTypographyProps={secondaryStyles} />
                            {item?.places?.length > 0 && <ListItemText primary="Sökte ort/plats" secondary={item?.places?.toString()} secondaryTypographyProps={secondaryStyles} />}
                        </ListItemButton>
                    </List>
                </Collapse>
            </div>;
        })}

        {(!loading && list?.length === 0) && <InputLabel sx={{ width: "100%", textAlign: "center" }}>
            Ingen registrerad besökare</InputLabel>}

        {loading && <div className="loading-wrapper">
            <CircularProgress size={25} color="inherit" />
            <p>Information laddas ...</p>
        </div>}
    </List>
}

export default Statistics;